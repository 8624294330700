<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">상세 정보</h2>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 428px" />
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <tr>
              <th>등록자</th>
              <td>{{ itemData.name }}</td>
              <th>공지 등록일</th>
              <td>{{ regDate }}</td>
            </tr>
            <tr>
              <th>공지 제목</th>
              <td colspan="3">
                <strong v-html="itemData.title" />
              </td>
            </tr>
            <!-- <tr>
              <th>공지기간</th><td colspan="3">{{ startDate + ' ~ ' + endDate }}</td>
            </tr> -->
            <tr>
              <th>공지 내용</th>
              <td colspan="3" class="td_text" v-html="noteDecoded" />
            </tr>
            <tr>
              <th>첨부 파일</th>
              <td colspan="3">
                <FileView :dataList="itemData.attachFileList || []" />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableView from "@/components/shared/tableView/TableView";
import Radio from "@/components/common/radio/Radio";
import FileView from "@/components/common/file/FileView";
import Sticky from "@/components/layout/content/Sticky.vue";
import PageMixin from "@/mixins/PageMixin";
import { getDateString } from "@/utils/dateUtils";
import ApiService from "@/services/ApiService";
import { decodeHTMLEntities } from "@/utils/stringUtils";

export default {
  name: "NoticeView",
  components: {
    PageWithLayout,
    TableView,
    Radio,
    FileView,
    Sticky,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},
    };
  },
  computed: {
    noteDecoded() {
      const note = this.itemData.contents;

      if (!note) return "";

      const result = decodeHTMLEntities(document, note);
      return result;
    },
    startDate() {
      if (!this.itemData.startDate) return "";
      return getDateString(this.itemData.startDate);
    },
    endDate() {
      if (!this.itemData.endDate) return "";
      return getDateString(this.itemData.endDate);
    },
    regDate() {
      if (!this.itemData.regDate) return "";
      return getDateString(this.itemData.regDate);
    },
  },
  beforeMount() {
    const seq = this.$route.params.id;
    const path = `${this.$apiPath.NOTICE}/${seq}`;

    this.getData(path);
  },
  methods: {
    async getData(path) {
      const result = await ApiService.shared.getData(path);
      if (!result.data) return;

      this.itemData = result.data;
    },
    onClickGoToList() {
      this.goToList();
    },
    onClickRemove() {
      const seq = this.$route.params.id;
      this.removeItem(seq);
    },
    goToList() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_NOTICE_LIST,
        query: this.$route.query,
      });
    },
    async removeItem(seq) {
      const result = await ApiService.shared.deleteData(`${this.$apiPath.NOTICE}/${seq}`);

      if (!result.data) return;

      this.goToList();
    },
    onClickModify() {
      const seq = this.$route.params.id;

      this.$router.push({
        path: `${this.$routerPath.SYSTEM_NOTICE_MODIFY}/${seq}`,
      });
    },
  },
};
</script>
